import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StaffService } from '../staff/staff.service';
import { convertFormDetaiToFormData } from './de-tai.form.service.utils';

@Injectable({ providedIn: 'root' })
export class DetaiThanhVienFormService {
    private _data: any;
    private _dataSub: Subject<any> = new Subject();

    public get data(): any {
        return this._data;
    }
    public set data(value: any) {
        this._data = value;
        this._dataSub.next(this._data);
    }
    public get dataSub(): Subject<any> {
        return this._dataSub;
    }

    constructor(private staffService: StaffService) {}

    async fetch() {
        let result: any = await this.staffService.getPartAll();
        if (result.status == 'error') this.data = [];
        this.data = result.staffs
            .map((staff: any) => {
                return {
                    ...staff.data,
                    id: staff.id,
                    ma: staff.data.ma_cb,
                    ten_cb: staff.data.ten,
                    ten: staff.data.ma_cb + ' - ' + staff.data.ho_ten_dem + ' ' + staff.data.ten,
                };
            })
            .sort((a: any, b: any) => {
                if (a.ma_cb > b.ma_cb) return 1;
                if (a.ma_cb < b.ma_cb) return -1;
                return 0;
            });
    }

    async fetchIfNotExists() {
        if (Array.isArray(this._data) && this._data.length > 0) return;
        await this.fetch();
    }
}
