import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { StaffInputFileType } from '../../interface/staff';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';

@Injectable({ providedIn: 'root' })
export class StaffService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async getPartAll(
        limit_offset: { limit: number; offset: number } | undefined = undefined,
        field: 'name' | 'code' | undefined = undefined
    ) {
        return this.httpClient
            .post<any>(
                this.hostnameService.HidsAPI_Readv1 + '/staff/get-part-all',
                {
                    limit_offset: limit_offset,
                    field: field,
                },
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }
}
