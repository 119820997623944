import { MCODES } from './roles.menu.utils';

export const MENU_ITEMS_DASHBOARD = {
    state: 'dashboards',
    name: 'menuitems.dashboard',
    type: 'link',
    icon: 'av_timer',
};

export const MENU_ITEMS_TAIKHOAN = {
    state: 'account',
    name: 'menuitems.tai_khoan',
    type: 'sub',
    icon: 'manage_accounts',
    children: [{ state: 'account-info', name: 'menuitems.account_info', type: 'link' }],
};

export const MENU_ITEMS_LUUTRU = {
    state: 'storage',
    name: 'menuitems.luu_tru',
    type: 'sub',
    icon: 'archive',
    children: [{ state: 'files-downloaded', name: 'menuitems.file_downloaded', type: 'link' }],
};

export const MENU_ITEMS_TIENICH = {
    state: 'utitlities',
    name: 'menuitems.tien_ich',
    type: 'sub',
    icon: 'extension',
    children: [
        { state: 'standard-address', name: 'menuitems.standard_address', type: 'link' },
        { state: 'standard-general-data', name: 'menuitems.standard_general_data', type: 'link' },
        { state: 'standard-staff', name: 'menuitems.standard_staff', type: 'link' },
    ],
};

export const MENU_ITEMS_DETAI = {
    state: 'detai',
    name: 'menuitems.detai',
    type: 'sub',
    icon: 'work',
    children: [{ state: 'list', name: 'menuitems.detai_list', type: 'link' }],
};

export const MENU_ITEMS: Map<string, any> = new Map<string, any>([
    [MCODES.dashboard, MENU_ITEMS_DASHBOARD],
    [MCODES.detai, MENU_ITEMS_DETAI],
    [MCODES.luutru, MENU_ITEMS_LUUTRU],
    [MCODES.tienich, MENU_ITEMS_TIENICH],
    [MCODES.taikhoan, MENU_ITEMS_TAIKHOAN],
]);

export const MENU_ITEMS_ORDER: string[] = [
    MCODES.dashboard,
    MCODES.detai,
    MCODES.luutru,
    MCODES.tienich,
    MCODES.taikhoan,
];
